import lib from '../../images/lib.jpg';
import grab from '../../images/lib_2.jpg';

export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Tales We Tell: More to Come',
  headline: '',
  description: '',
  buttonLabel: 'More To Come',
  imgStart: false,
  img: lib,
  alt: 'Library',
  dark: true,
  primary: true,
  darkText: false,
  linkString: '/'
};

export const homeObjTwo = {
  id: 'discussion',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '',
  headline: '',
  description: '',
  buttonLabel: '',
  imgStart: true,
  img: grab,
  alt: 'discussion',
  dark: false,
  primary: false,
  darkText: true,
  linkString: '/'
};

