import React, { useState } from 'react';
// import SignUp from '../components/SignUp';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

function SignupPage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ScrollToTop />
      <Navbar toggle={toggle} />
      {/* <SignUp /> */}
      <Footer />
    </>
  );
}

export default SignupPage;
