import React, { useState, useEffect } from 'react';
import { Button } from '../ButtonElements';
// import GetHeroVideo from './GetHeroVideo'
import video from '../../videos/v2.mp4';

import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);
  // const [video, setVideo] = useState({ fileUrl: '' });

  // useEffect(() => {
  //   GetHeroVideo().then(vid => setVideo({ fileUrl: vid }));
  // }, []);


  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>Stories We Feel</HeroH1>
        <HeroP>
          We all have a story. We have that in common. We also have stories we were told as kids. Some we look back on with fondness. Others guided us through hard times. Some made us wonder and spurred our imaginations. What stories will we tell our kids. Make the moments count by telling your own story.
        </HeroP>
        <HeroBtnWrapper>
          <Button
            to='/'
            smooth='true'
            duration={500}
            spy='true'
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
          // onMouseEnter={onHover}
          // onMouseLeave={onHover}
          >
            Coming Soon {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
