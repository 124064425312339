import React from 'react'
import './App.css';
import Home from './pages';
import SignIn from './pages/signin'
import SignUp from './pages/signup'
import ForgotPassword from './pages/forgotpassword';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import TermsPage from './pages/Terms';



function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path='/' component={Home} exact />
          <Route path='/signin' component={SignIn} exact />
          <Route path='/signup' component={SignUp} exact />
          <Route path='/reset' component={ForgotPassword} exact />
          <Route path='/termsofservice' exact component={TermsPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
