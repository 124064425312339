import React, { useState } from 'react';
// import { Account } from '../components/Accounts';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSectionRouter from '../components/InfoSectionRouter';
import {
  homeObjOne, analysisInfoSection, performanceInfoSection,
  // homeObjThree
} from '../components/InfoSectionRouter/RouterData';
function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    // <Account>
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSectionRouter {...homeObjOne} />
      {/* <InfoSectionRouter {...analysisInfoSection} />
      <InfoSectionRouter {...performanceInfoSection} /> */}
      <Footer />
    </>
    // </Account>
  );
}

export default Home;
