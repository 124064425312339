import React, { useState } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/Footer';
// import { Account } from '../components/Accounts';
// import ForgotPassword from '../components/ForgotPassword';
import Navbar from '../components/Navbar';

function ForgotPasswordPage() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
        // <Account>
        <>
            <ScrollToTop />
            <Navbar toggle={toggle} />
            {/* <ForgotPassword /> */}
            <Footer />
        </>
        // </Account>
    );
}

export default ForgotPasswordPage;
