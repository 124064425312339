import React, { useState } from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
// import TermsOfService from '../components/TermsOfServiceForms';

function TermsPage() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <ScrollToTop />
            <Navbar toggle={toggle} />
            {/* <TermsOfService /> */}
            <Footer />
        </>
    );
}

export default TermsPage;

